<template>
  <div id="app">

    <div id="box">
      <input type="text" v-model="message1" @focus="list1_show=true" @blur="list1_show=false">
      <ul v-show="list1_show">
        <li v-for="(option, index) in matchedOptions1" :key="index" @mousedown="message1 = option">{{ option }}</li>
      </ul>
    </div>

  </div>
</template>


<style scoped>
</style>

<script>
export default {
  data () {
    return {
      list1_show:false,
      message1: '',
      options1: ['html', 'css', 'javascript']
    };
  },
  methods: {
      
  },
  computed: {
    matchedOptions1 () {
      if (this.message1 !== '') {
        return this.options1.filter(option => option.includes(this.message1))
      }
      return this.options1
    }
  }

};
</script>
